import { gql } from "@apollo/client";

export const CREATE_SOURCE = gql`
  mutation CreateSource($createSource: CreateSourceInput!) {
    createSource(createSource: $createSource) {
      id
      name
      email
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation CreateCoupon($data: CreateCouponInput!) {
  createCoupon(data: $data) {
    id
    code
  }
}
`;
