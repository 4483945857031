import { useEffect, useState } from 'react';
import { Modal } from '../../../components/modal/modal';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CREATE_COUPON } from '../../../graphql/mutations/referral';
import { GET_COUPONS_WITH_INVESTOR_COUNT } from '../../../graphql/queries/referral';

interface CouponWithInvestorCount {
    coupon: {
        id: string;
        code: string;
        incentive: string;
    };
    totalInvestors: number;
}

interface CouponsData {
    getCouponsBySourceId: CouponWithInvestorCount[];
}

interface CouponsVariables {
    sourceId: string;
}

interface Props {
    show: boolean,
    sourceId: string,
    handleClose: () => void;
}

export function AddCouponModal({ show, sourceId, handleClose }: Props) {


    const navigate = useNavigate();
    const [newCouponCode, setNewCouponCode] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [infoMessage, setInfoMessage] = useState<string>("");

    const [createCoupon, { data: createCouponData, loading: createCouponLoading, error: createCouponError }] = useMutation(CREATE_COUPON);
    const { loading: loadingCoupons, error: getCouponsError, data: couponsData, refetch: refetchCoupons } = useQuery<CouponsData, CouponsVariables>(GET_COUPONS_WITH_INVESTOR_COUNT, {
        variables: { sourceId: sourceId },
    });

    useEffect(() => {
        if (createCouponError) {
            const errorDetail = createCouponError.message || 'Failed to add Coupon! Please try again';
            setErrorMessage(errorDetail);
        }
    }, [createCouponError]);

    useEffect(() => {
        if (createCouponData) {
            setInfoMessage("Coupon added successfully!");
            setErrorMessage('');
            handleClose();
        }
    }, [createCouponData]);

    useEffect(() => {
        if (createCouponLoading) {
            setInfoMessage("Creating Coupon...");
        }
    }, [createCouponLoading]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleAddCoupon();
        }
    };

    const handleAddCoupon = async () => {
        if (!newCouponCode) {
            setErrorMessage("Please fill in the Coupon Code.");
            return;
        }
        if (!sourceId) {
            setErrorMessage("Source not found");
            return;
        }

        const normalizedCode = newCouponCode.replace(/[\s-_]/g, '');

        await createCoupon({
            variables: {
                data: { code: normalizedCode, sourceId: sourceId },
            },
        });
        refetchCoupons();
        setErrorMessage('');
    };

    return (
        <>
            {show &&
                <Modal title={"Add Source"} onClose={handleClose}>
                    <div className="space-y-2 mb-6 flex gap-2 flex-col">
                        <section>
                            <label htmlFor="couponCode" className="text-sm font-semibold mb-2">Coupon Code</label>
                            <input
                                id="couponCode"
                                value={newCouponCode}
                                onChange={(e) => { setNewCouponCode(e.target.value); setErrorMessage('') }}
                                placeholder="Please enter new coupon code"
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pn-blue focus:border-pn-blue block p-2.5 pac-target-input"
                                onKeyDown={handleKeyDown}

                            />
                        </section>

                        {infoMessage && (
                            <div className="mb-4 p-4 bg-gray-100 text-center text-bold text-gray-700 rounded-lg">
                                {infoMessage}
                            </div>
                        )}
                        {errorMessage && (
                            <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                                {errorMessage}
                            </div>
                        )}

                        <div className='flex gap-2'>
                            <button
                                onClick={handleClose}
                                className="py-2 text-pn-blue bg-white rounded-lg hover:bg-gray-300 mt-2 flex-1 border-[1px] hover:border-white border-pn-blue"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddCoupon}
                                className="py-2 bg-pn-blue text-white rounded-lg hover:bg-pn-dark-yellow disabled:bg-gray-300 mt-2 flex-1 "
                                disabled={createCouponLoading || !!errorMessage}
                            >
                                {createCouponLoading ? "Adding..." : "Add Coupon"}
                            </button>
                        </div>
                    </div>

                </Modal>
            }
        </>
    );
}
