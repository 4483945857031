import { Role, User } from "../../../__generated__/graphql";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";
import { FormSelect } from "../../../components/form/select-type";

interface Props {
  user?: User;
  roles: Role[];
  mode: 'add' | 'edit';
}

export function AdminFormProfileSection({ user, roles, mode }: Props) {
  return (
    <FormSection title="Admin Profile" description="Basic details about the admin">
      <>
        <FormInput label="First name" name="firstName" autoFocus required defaultValue={user?.firstName} />
        <FormInput label="Last name" name="lastName" required defaultValue={user?.lastName} />
        <FormInput label="Email" type="email" name="email" required defaultValue={user?.email} />
        <FormInput label="Password" type="password" name="password" required={mode === 'add'} defaultValue={''} placeholder="at least 5 characters long" />
        {mode === 'edit' && <p className="mt-[-1.5rem] mb-[1.5rem] text-sm text-slate-500">(Leave empty to keep unchanged)</p>}
        <FormInput label="Phone" type="phone" name="phone" defaultValue={user?.phone || undefined} />
        <FormSelect label="Role" name="role" required defaultValue={user?.adminProfile?.role?.id} options={roles.map(role => ({ value: role.id, label: role.name }))} />
      </>
    </FormSection>
  )
}