import { gql } from "@apollo/client";

export const QUERY_GET_PAGINATED_USER_LIST = gql`
  query findPaginatedUsers(
    $take: Int
    $skip: Int
    $startDate: String!
    $endDate: String!
    $where: WhereInput
    $searchTerm: String
  ) {
    users(
      paginationOptions: { take: $take, skip: $skip }
      startDate: $startDate
      endDate: $endDate
      where: $where
      searchTerm: $searchTerm
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        createdAt
        status
        totalDeposits
        totalInvestments
        investorProfile {
          balance
          hubSpotDealStatus
        }
        adminProfile {
          id
          role {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export const QUERY_GET_ALL_USER_LIST = gql`
  query findUsers(
    $startDate: String!
    $endDate: String!
    $where: WhereInput
    $sourceId: String
  ) {
    unPaginatedUsers(
      startDate: $startDate
      endDate: $endDate
      where: $where
      sourceId: $sourceId
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        createdAt
        status
        totalDeposits
        totalInvestments
        investorProfile {
          balance
        }
        adminProfile {
          id
          role {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export const QUERY_GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    userById(id: $id) {
      id
      firstName
      lastName
      email
      phone
      status
      investorProfile {
        balance
        solanaWalletAddress
        rewardBalance
        coupon {
          code
        }
      }
    }
  }
`;

export const QUERY_GET_ADMIN_BY_ID = gql`
  query getUser($id: String!) {
    userById(id: $id) {
      id
      firstName
      lastName
      email
      phone
      status
      adminProfile {
        id
        role {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_MY_ROLE = gql`
  query getMyRole {
    myRole {
      name
      assignments {
        module
        action
      }
    }
  }
`;

// export const GET_USER_BY_EMAIL = gql`
//   query getUserByEmail($email: String!) {
//     userByEmail(email: $email) {
//       id
//       email
//       firstName
//       lastName
//     }
//   }
// `;
