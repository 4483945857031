import { useEffect, useState } from "react";
import { Property, PropertyFile } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { getPropertyImageUrl } from "../../../utils/image.utils";
import { PropertyImagesModal } from "./images-modal";

interface Props {
  property?: Property
  mode: 'add' | 'edit';
  refresh?: () => void;
}

export function PropertyImagesSection({ property, mode, refresh }: Props) {

  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState<PropertyFile[]>([]);

  useEffect(() => {
    if (refresh) {
      refresh();
    }
  }, [showModal]);

  useEffect(() => {
    if (property && property.images) {
      setImages(property.images.slice().sort((a, b) => a.order - b.order));
    }
  }, [property, property?.images, showModal]);

  return (
    <>
      <PropertyImagesModal property={property} show={showModal} onShowHide={setShowModal} refresh={refresh} />

      <div className="p-4 bg-white border rounded-lg shadow">
        <h2 className="mb-2 text-lg font-semibold">Images {property && images && images?.length > 0 ? `(${images.length})` : ''}</h2>
        {property && images && images.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-4 overflow-auto max-h-80">
            {images.map((image, index) => (
              <div key={index} className="relative">
                <img src={getPropertyImageUrl(property.id, image)} alt="Property" className="object-cover w-24 h-24 rounded-lg" />
              </div>
            ))}
          </div>
        )}
        {property && images?.length === 0 && <p className="mb-4 text-sm">No images added</p>}
        {mode === 'add' && <p className="mb-4 text-sm text-slate-400">Property images can be added after the property is created</p>}
        {mode === 'edit' && <p className="mb-4 text-sm text-slate-400">Please add atleast 3 images</p>}
        <FormButton title="Manage Images" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  );
}