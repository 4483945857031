import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_COUPONS, GET_ALL_SOURCES, GET_ALL_SOURCES_WITH_TOTALS } from "../../graphql/queries/referral";
import { CREATE_COUPON, CREATE_SOURCE } from "../../graphql/mutations/referral";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AuthService } from "../../services/auth.service";
import { RoleAction, RoleModule, Source } from "../../__generated__/graphql";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import { AppRoute } from "../../routes";
import { useNavigate } from "react-router-dom";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { AddSourceModal } from "./modals/add_source.modal";

interface SourceWithTotals {
    source: Source;
    totalCoupons: number;
    totalInvestors: number;
}

export function ReferralListPage() {

    const [newCouponCode, setNewCouponCode] = useState("");
    const [selectedSourceId, setSelectedSourceId] = useState("");
    const [showAddSource, setShowAddSource] = useState(false);
    const [selectedRow, setSelectedRow] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const navigate = useNavigate();


    const { data: sourcesData, loading: loadingSources, error: getSourcesError, refetch: refetchSources } = useQuery<{
        getAllSourcesWithTotals: SourceWithTotals[];
    }>(GET_ALL_SOURCES_WITH_TOTALS);
    const { data: couponsData, error: getCouponsError, refetch: refetchCoupons } = useQuery(GET_ALL_COUPONS);

    function selectSource(id: string) {
        if (!AuthService.hasRole(RoleModule.Referral, RoleAction.Update)) {
            return;
        }
        navigate(`${AppRoute.ReferralSource}/${id}`);
    }

    useEffect(() => {
        setErrorMessage('');
    }, [showAddSource]);

    useEffect(() => {
        getSourcesError && setErrorMessage("Failed to load all Sources! Please try again");
        getCouponsError && setErrorMessage("Failed to load all Coupons! Please try again");
    }, [getCouponsError, getSourcesError]);

    useEffect(() => {
        console.log("sourcesData: ", sourcesData)
    }, [sourcesData]);
    useEffect(() => {
        console.log("couponsData: ", couponsData)
    }, [couponsData]);

    return (<>
        <Sidebar />
        <section
            className='mt-0 content full properties sm:mt-14'
        >
            <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Referral Sources
            </h1>
            <div className="flex flex-col-reverse md:flex-row md:justify-end">
                {AuthService.hasRole(RoleModule.Referral, RoleAction.Add) && (
                    <div className="flex gap-2 mb-2 md:mb-0">
                        <a
                            href="#"
                            className="flex flex-wrap content-center justify-center w-full h-12 gap-2 px-4 py-2 transition-all duration-300 rounded-lg mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow"
                            onClick={(e) => { e.preventDefault(); setShowAddSource(true) }}
                        >
                            <PlusCircleIcon width={24} className="text-white" />
                            <span className="self-center text-sm font-semibold text-white">
                                Add Source
                            </span>
                        </a>
                    </div>
                )}

            </div>
            {errorMessage && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                    {errorMessage}
                </div>
            )}

            <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
                <thead>
                    <tr>
                        <th className="px-4 py-3 border-b">Name</th>
                        <th className="hidden px-4 py-3 border-b md:table-cell">Email</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Total Coupons</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Total Investors</th>
                    </tr>
                </thead>
                <tbody>
                    {!loadingSources &&
                        sourcesData &&
                        sourcesData.getAllSourcesWithTotals &&
                        sourcesData.getAllSourcesWithTotals.map(({ source, totalCoupons, totalInvestors }, index) => (
                            <tr
                                key={index}
                                className={`hover:bg-pn-blue hover:text-white cursor-pointer transition-all duration-300 ${selectedRow === index
                                    ? "bg-pn-dark-yellow text-pn-blue"
                                    : ""
                                    }`}
                                onClick={() => selectSource(source.id)}
                            >
                                <td className="px-4 py-3 text-sm text-bold">{source.name}</td>
                                <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                                    {source.email}
                                </td>
                                <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                                    {totalCoupons}
                                </td>
                                <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                                    {totalInvestors}
                                </td>
                            </tr>
                        ))}
                    {loadingSources && (
                        <tr>
                            <td colSpan={5} className="text-center">
                                <LoadingPlaceholder />
                            </td>
                        </tr>
                    )}
                    {!loadingSources &&
                        (!sourcesData || !sourcesData.getAllSourcesWithTotals || sourcesData.getAllSourcesWithTotals.length === 0) && (
                            <tr>
                                <td colSpan={5} className="text-sm text-center">
                                    <div className="flex flex-col items-center gap-2 p-5">
                                        <InformationCircleIcon width={60} />
                                        <p className="font-bold">
                                            No Sources
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

            <div className="container mx-auto p-4">
                {showAddSource && (
                    <AddSourceModal
                        show={showAddSource}
                        handleClose={() => setShowAddSource(false)}
                    />
                )}
            </div>
        </section>
    </>
    );
}
