import { gql } from "@apollo/client";

export const GET_ALL_SOURCES = gql`
  query GetAllSources {
    getAllSources {
      id
      name
      email
    }
  }
`;


export const GET_ALL_COUPONS = gql`
  query GetAllCoupons {
    getAllCoupons {
      id
      code
      incentive
    }
  }
`;

export const GET_ALL_SOURCES_WITH_TOTALS = gql`
  query GetAllSourcesWithTotals {
    getAllSourcesWithTotals {
      source {
        id
        name
        email
      }
      totalCoupons
      totalInvestors
    }
  }
`;

export const GET_SOURCE_BY_ID = gql`
  query GetSourceById($id: String!) {
    getSourceById(id: $id) {
      id
      name
      email
    }
  }
`;

export const GET_COUPONS_WITH_INVESTOR_COUNT = gql`
  query GetCouponsBySource($sourceId: String!) {
    getCouponsBySourceId(sourceId: $sourceId) {
      coupon {
        id
        code
        incentive
      }
      totalInvestors
    }
  }
`;

export const GET_USER_BY_REFERRAL = gql`
  query GetUserByReferral($sourceId: String!) {
    getUserByReferralAndCoupon(sourceId: $sourceId) {
      user {
        id
        firstName
        lastName
        email
        phone
        status
        investorProfile {
          balance
          hubSpotDealStatus
        }
        createdAt
      }
      coupon
    }
  }
`;

export const GET_USERS_BY_COUPON = gql`
  query GetUsersByCoupon($couponId: String!) {
    getUsersByCoupon(couponId: $couponId) {
      coupon
      users {
        id
        firstName
        lastName
        email
        phone
        status
        investorProfile {
          balance
          hubSpotDealStatus
        }
        createdAt
      }
    }
  }
`;