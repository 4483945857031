import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_COUPONS_WITH_INVESTOR_COUNT, GET_SOURCE_BY_ID, GET_USER_BY_REFERRAL, GET_USERS_BY_COUPON } from "../../graphql/queries/referral";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AuthService } from "../../services/auth.service";
import { RoleAction, RoleModule, Source, User, UserStatus, UserTotals } from "../../__generated__/graphql";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import { AppRoute } from "../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingPlaceholder } from "../../components/loading/placeholder";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { AddCouponModal } from "./modals/add_coupon.modal";
import { FormButton } from "../../components/form/button";
import { QUERY_GET_ALL_USER_LIST } from "../../graphql/queries/user";
import { PaginatedData } from "../../interfaces/pagination.interface";
import { toCurrency, toDateTimeWithYearStarting } from "../../utils/string.utils";
import { MUTATION_UPDATE_INVESTOR } from "../../graphql/mutations/investor";
import { MUTATION_SEND_UNVERIFIED_REMINDER_EMAIL } from "../../graphql/mutations/admin";

interface CouponWithInvestorCount {
    coupon: {
        id: string;
        code: string;
        incentive: string;
    };
    totalInvestors: number;
}

interface CouponsData {
    getCouponsBySourceId: CouponWithInvestorCount[];
}

interface CouponsVariables {
    sourceId: string;
}

interface UserWithCoupon {
    user: User;
    coupon: string;
}

export function ReferralSourcePage() {

    const [showAddCoupon, setShowAddCoupon] = useState(false);
    const [selectedRow, setSelectedRow] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [couponHasUsers, setCouponHasUsers] = useState<boolean>(false);

    const navigate = useNavigate();
    const { id } = useParams();

    const { loading, error: getSourceError, data: sourceData, refetch: refetchSource } = useQuery(GET_SOURCE_BY_ID, {
        variables: { id: id },
    });
    const { loading: loadingCoupons, error: getCouponsError, data: couponsData, refetch: refetchCoupons } = useQuery<CouponsData, CouponsVariables>(GET_COUPONS_WITH_INVESTOR_COUNT, {
        variables: { sourceId: id! },
    });

    const { data: users, loading: usersLoading, error: usersLoadingError, refetch: refetchUsers } = useQuery(GET_USER_BY_REFERRAL, { variables: { sourceId: id! } });

    const [getUsersByCoupon, { data: usersWithCoupon, loading: loadingUserWithCoupon, error: errorGettingUserWithCoupon }] = useLazyQuery(GET_USERS_BY_COUPON);

    useEffect(() => {
        setErrorMessage('');
    }, [showAddCoupon]);
    useEffect(() => {
        console.log("users : ", users)
    }, [users]);

    useEffect(() => {
        id && refetchUsers({ variables: { sourceId: id! } })
    }, [id]);
    useEffect(() => {
        if (usersWithCoupon && usersWithCoupon.getUsersByCoupon && usersWithCoupon.getUsersByCoupon.users && usersWithCoupon.getUsersByCoupon.users.length > 0) {
            setCouponHasUsers(true);
        }
        else {
            setCouponHasUsers(false);
        }
        console.log("usersWithCoupon: ", usersWithCoupon)

    }, [usersWithCoupon]);


    useEffect(() => {
        getSourceError && setErrorMessage("Failed to load Source! Please try again");
        getCouponsError && setErrorMessage("Failed to load Coupons! Please try again");
        usersLoadingError && setErrorMessage("Failed to Investors! Please try again");
        usersLoadingError && setErrorMessage("Failed to Investors with specific Coupon! Please try again");

    }, [getCouponsError, getSourceError, usersLoadingError]);

    function selectCoupon(id: string) {
        getUsersByCoupon({ variables: { couponId: id } });
    }

    function formatString(status: string): string {
        return status
            .split(/[_-]+/) // Split the string by underscores and hyphens
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter and make the rest lowercase
            .join(' '); // Join the words with a space
    }

    return (<>
        <Sidebar />
        <section
            className='mt-0 content full properties sm:mt-14'
        >
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-4">
                    <div>
                        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Source Referrals</h1>
                    </div>
                </div>

                <div className="text-right">
                    <p className="text-lg font-semibold text-gray-800">
                        {sourceData?.getSourceById?.name}
                    </p>
                    <p className="text-sm text-gray-500">
                        {sourceData?.getSourceById?.email}
                    </p>
                </div>
            </div>
            {errorMessage && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                    {errorMessage}
                </div>
            )}
            <div className="flex flex-col-reverse md:flex-row md:justify-end mb-2">
                {AuthService.hasRole(RoleModule.Referral, RoleAction.Add) && (
                    <div className="flex gap-2 mb-2 md:mb-0">
                        <a
                            href="#"
                            className="flex flex-wrap content-center justify-center w-full h-12 gap-2 px-4 py-2 transition-all duration-300 rounded-lg mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow"
                            onClick={(e) => { e.preventDefault(); setShowAddCoupon(true) }}
                        >
                            <PlusCircleIcon width={24} className="text-white" />
                            <span className="self-center text-sm font-semibold text-white">
                                Add Coupon
                            </span>
                        </a>
                    </div>
                )}

            </div>

            <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
                <thead>
                    <tr>
                        <th className="px-4 py-3 border-b">Coupon Code</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Incentive</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Total Investors</th>
                    </tr>
                </thead>
                <tbody>
                    {!loadingCoupons &&
                        couponsData &&
                        couponsData.getCouponsBySourceId &&
                        couponsData.getCouponsBySourceId.map(({ coupon, totalInvestors }, index) => (
                            <tr
                                key={index}
                                className={`hover:bg-pn-blue hover:text-white cursor-pointer transition-all duration-300 ${selectedRow === index
                                    ? "bg-pn-dark-yellow text-pn-blue"
                                    : ""
                                    }`}
                                onClick={() => selectCoupon(coupon.id)}
                            >
                                <td className="px-4 py-3 text-sm text-center text-bold">{coupon.code}</td>

                                <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                                    {coupon.incentive}
                                </td>
                                <td className="hidden px-4 py-3 text-sm text-center md:table-cell">
                                    {totalInvestors}
                                </td>
                            </tr>
                        ))}
                    {loadingCoupons && (
                        <tr>
                            <td colSpan={5} className="text-center">
                                <LoadingPlaceholder />
                            </td>
                        </tr>
                    )}
                    {!loadingCoupons &&
                        (!couponsData || !couponsData.getCouponsBySourceId || couponsData.getCouponsBySourceId.length === 0) && (
                            <tr>
                                <td colSpan={5} className="text-sm text-center">
                                    <div className="flex flex-col items-center gap-2 p-5">
                                        <InformationCircleIcon width={60} />
                                        <p className="font-bold">
                                            No Coupons for  '<strong>{sourceData?.getSourceById?.name}</strong>'
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

            {couponHasUsers &&
                <>
                    <div className="flex items-center space-x-4 mt-[5rem]">
                        <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Referrals with Coupon "{usersWithCoupon.getUsersByCoupon.coupon}"</h1>
                    </div>

                    <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
                        <thead>
                            <tr>
                                <th className='px-4 py-3 border-b'>Investors</th>
                                <th className='hidden px-4 py-3 border-b md:table-cell'>Registered on</th>
                                <th className='hidden px-4 py-3 border-b md:table-cell'>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loadingUserWithCoupon && couponHasUsers &&
                                usersWithCoupon.getUsersByCoupon.users.map((user: User, index: number) => (
                                    <tr
                                        key={index}
                                        className='transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white'
                                        onClick={() => { navigate(`/investor/${user.id}`); console.log("user", user) }}
                                    >
                                        <td className='flex flex-col justify-center px-4 py-3 text-sm'>
                                            <div className='font-bold text-inherit'>{[user.firstName, user.lastName].join(' ')}</div>
                                            <div className='text-[0.875rem] text-inherit'>{user.email}</div>
                                            <div className='text-[0.875rem] text-inherit'>{user.phone}</div>
                                        </td>
                                        <td className='hidden px-4 py-3 text-sm text-center md:table-cell'>
                                            {toDateTimeWithYearStarting(user.createdAt)}
                                        </td>
                                        <td className='hidden px-4 py-3 text-sm text-center md:table-cell'>
                                            {formatString(user.status)}
                                        </td>
                                    </tr>
                                ))}
                            {loadingUserWithCoupon && (
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        <LoadingPlaceholder />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            }


            <div className="flex items-center space-x-4 mt-[5rem]">
                <h1 className="mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Referrals</h1>
            </div>

            <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto mt-3">
                <thead>
                    <tr>
                        <th className='px-4 py-3 border-b'>Investors</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Registered on</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Status</th>
                        <th className='hidden px-4 py-3 border-b md:table-cell'>Coupon Used</th>
                    </tr>
                </thead>
                <tbody>
                    {!usersLoading && users && users.getUserByReferralAndCoupon && users.getUserByReferralAndCoupon.map((user: UserWithCoupon, index: number) => (
                        <tr
                            key={index}
                            className='transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white'
                            onClick={() => { navigate(`/investor/${user.user.id}`); console.log("user", user.user) }}
                        >
                            <td className='flex flex-col justify-center px-4 py-3 text-sm'>
                                <div className='font-bold text-inherit'>{[user.user.firstName, user.user.lastName].join(' ')}</div>
                                <div className='text-[0.875rem] text-inherit'>{user.user.email}</div>
                                <div className='text-[0.875rem] text-inherit'>{user.user.phone}</div>
                            </td>
                            <td className='hidden px-4 py-3 text-sm text-center md:table-cell'>
                                {toDateTimeWithYearStarting(user.user.createdAt)}
                            </td>
                            <td className='hidden px-4 py-3 text-sm text-center md:table-cell'>
                                {formatString(user.user.status)}
                            </td>
                            <td className='hidden px-4 py-3 text-sm text-bold text-center md:table-cell'>
                                {user.coupon}
                            </td>
                        </tr>
                    ))}
                    {usersLoading && <tr><td colSpan={5} className='text-center'><LoadingPlaceholder /></td></tr>}
                    {!usersLoading && (!users || !users.getUserByReferralAndCoupon || users.getUserByReferralAndCoupon.length === 0) &&
                        <tr><td colSpan={6} className='text-sm text-center'>
                            <div className='flex flex-col items-center gap-2 p-5'>
                                <InformationCircleIcon width={60} />
                                <p className='font-bold'>No Investors Referred </p>
                            </div>
                        </td></tr>}
                </tbody>
            </table>

            <div className="container mx-auto p-4">
                {showAddCoupon && (
                    <AddCouponModal
                        show={showAddCoupon}
                        sourceId={id!}
                        handleClose={() => setShowAddCoupon(false)}
                    />
                )}
            </div>
        </section>
    </>
    );
}
