import { RoleAction, RoleModule } from "../../__generated__/graphql";

export const ROLE_ASSIGNMENTS: { module: RoleModule, description: string, actions: { name: RoleAction, description: string }[] }[] = [
  {
    module: RoleModule.Analytics,
    description: 'A collection of analytics for monitoring, reporting and decision making in the PropNerd platform',
    actions: [
      { name: RoleAction.View, description: 'Allow viewing admin analytics' },
    ]
  },
  {
    module: RoleModule.Property,
    description: 'Property management module for the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing properties' },
      { name: RoleAction.ListAvailable, description: 'Allow listing available properties' },
      { name: RoleAction.ListNew, description: 'Allow listing new properties'},
      { name: RoleAction.ListRented, description: 'Allow listing rented properties'},
      { name: RoleAction.ListCompleted, description: 'Allow listing completed properties'},
      { name: RoleAction.ListExited, description: 'Allow listing exited properties'},
      { name: RoleAction.Add, description: 'Allow adding properties' },
      { name: RoleAction.Update, description: 'Allow updating properties'},
      { name: RoleAction.Delete, description: 'Allow deleting properties'},
      { name : RoleAction.ListInactive, description: 'Allow listing inactive properties'}
    ]
  },
  {
    module: RoleModule.Investor,
    description: 'Investor management module for the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing investors' },
      { name: RoleAction.ListActive, description: 'Allow listing active investors' },
      { name: RoleAction.ListUnverified, description: 'Allow listing unverified investors' },
      { name: RoleAction.ListBlocked, description: 'Allow listing blocked investors' },
      { name: RoleAction.ListHighBalance, description: 'Allow listing investors with high balance' },
      { name: RoleAction.Block, description: 'Allow blocking investors' },
      { name: RoleAction.Unblock, description: 'Allow unblocking investors' },
      { name: RoleAction.UnverifiedReminderEmail, description: 'Allow sending unverified reminder email to investors' },
      { name: RoleAction.ListDeposited, description: 'Allow listing investors who did deposit but never invested' },
      { name: RoleAction.ListInvested, description: 'Allow listing investors who have done some investment' },
    ]
  },
  {
    module: RoleModule.Transaction,
    description: 'Monitor deposits and investments in the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing deposits and investments' },
    ]
  },
  {
    module: RoleModule.Leads,
    description: 'Lead management module for the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing all leads' },
      { name: RoleAction.ListSubscriber, description: 'Allow listing subscriber leads' },
      { name: RoleAction.ListLead, description: 'Allow listing general leads' },
      { name: RoleAction.ListMarketingQualifiedLead, description: 'Allow listing marketing qualified leads (MQLs)' },
      { name: RoleAction.ListSalesQualifiedLead, description: 'Allow listing sales qualified leads (SQLs)' },
      { name: RoleAction.ListOpportunity, description: 'Allow listing leads marked as opportunities' },
      { name: RoleAction.ListCustomer, description: 'Allow listing customer leads' },
      { name: RoleAction.ListEvangelist, description: 'Allow listing leads classified as evangelists' },
      { name: RoleAction.ListOther, description: 'Allow listing other leads not categorized' },
    ]
  },
  {
    module: RoleModule.Cities,
    description: 'City management module for the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing cities' },
      { name: RoleAction.Add, description: 'Allow adding cities' },
      { name: RoleAction.Update, description: 'Allow updating cities' },
      { name: RoleAction.Delete, description: 'Allow deleting cities' },
    ]
  },
  {
    module: RoleModule.Admin,
    description: 'Admin user management module for the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing admin users' },
      { name: RoleAction.ListActive, description: 'Allow listing active admin users' },
      { name: RoleAction.ListBlocked, description: 'Allow listing blocked admin users' },
      { name: RoleAction.Add, description: 'Allow adding admin users' },
      { name: RoleAction.Update, description: 'Allow updating admin users' },
      { name: RoleAction.Delete, description: 'Allow deleting admin users'},
      { name: RoleAction.Block, description: 'Allow blocking admin users' },
      { name: RoleAction.Unblock, description: 'Allow unblocking admin users' },
    ]
  },
  {
    module: RoleModule.Role,
    description: 'Role management module for admins in the PropNerd platform',
    actions: [
      { name: RoleAction.List, description: 'Allow listing roles' },
      { name: RoleAction.Add, description: 'Allow adding roles' },
      { name: RoleAction.Update, description: 'Allow updating roles'},
      { name: RoleAction.Delete, description: 'Allow deleting roles' },
    ]
  },
  {
    module: RoleModule.Cms,
    description: 'Cms management module for admins in the PropNerd platform',
    actions: [
      { name: RoleAction.Update, description: 'Allow updating cms'},
    ]
  },
  {
    module: RoleModule.Referral,
    description: 'Referral management module for admins in the PropNerd platform',
    actions: [
      { name: RoleAction.Add, description: 'Allow to add Sources'},
      { name: RoleAction.View, description: 'Allow to view referrals'},
      { name: RoleAction.List, description: 'Allow to list all referrals'},
      { name: RoleAction.Update, description: 'Allow updating referral'},
    ]
  },
];