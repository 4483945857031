import { useEffect, useState } from "react";
import { Property, PropertyFile } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { getPropertyVideoUrl } from "../../../utils/video.utils";
import { PropertyVideosModal } from "./videos-modal";

interface Props {
  property?: Property
  mode: 'add' | 'edit';
  refresh?: () => void;
}

export function PropertyVideosSection({ property, mode, refresh }: Props) {

  const [showModal, setShowModal] = useState(false);
  const [videos, setVideos] = useState<PropertyFile[]>([]);

  useEffect(() => {
    if (refresh) {
      refresh();
    }
  }, [showModal]);

  useEffect(() => {
    if (property && property.videos) {
      setVideos(property.videos.slice().sort((a, b) => a.order - b.order));
    }
  }, [property, property?.videos, showModal]);

  return (
    <>
      <PropertyVideosModal property={property} refresh={refresh} show={showModal} onShowHide={setShowModal} />

      <div className="p-4 bg-white border rounded-lg shadow yellow-scrollbar">
        <h2 className="mb-2 text-lg font-semibold">Videos {property && videos && videos?.length > 0 ? `(${videos.length})` : ''}</h2>
        {property && videos && videos.length > 0 && (
          <div className="flex flex-wrap gap-2 pr-2 mb-4 overflow-auto max-h-80">
            {videos.map((video, index) => (
              <div key={index} className="relative">
                <video src={getPropertyVideoUrl(property.id, video)} controls disablePictureInPicture controlsList="nodownload noremoteplayback noplaybackrate" />
              </div>
            ))}
          </div>
        )}
        {property && videos?.length === 0 && <p className="mb-4 text-sm">No videos added</p>}
        {mode === 'add' && <p className="mb-4 text-sm text-slate-400">Property videos can be added after the property is created</p>}
        <FormButton title="Manage Videos" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  );
}