import { useEffect, useState } from 'react';
import { Source } from '../../../__generated__/graphql';
import { Modal } from '../../../components/modal/modal';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CREATE_SOURCE } from '../../../graphql/mutations/referral';
import { GET_ALL_SOURCES_WITH_TOTALS } from '../../../graphql/queries/referral';

interface SourceWithTotals {
    source: Source;
    totalCoupons: number;
    totalInvestors: number;
}

interface Props {
    show: boolean,
    handleClose: () => void;
}

export function AddSourceModal({ show, handleClose }: Props) {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [infoMessage, setInfoMessage] = useState<string>("");
    const [newSourceName, setNewSourceName] = useState("");
    const [newSourceEmail, setNewSourceEmail] = useState("");

    const [createSource, { data: createSourceData, loading: createSourceLoading, error: createSourceError }] = useMutation(CREATE_SOURCE);
    const { data: sourcesData, loading: loadingSources, error: getSourcesError, refetch: refetchSources } = useQuery<{
        getAllSourcesWithTotals: SourceWithTotals[];
    }>(GET_ALL_SOURCES_WITH_TOTALS);

    useEffect(() => {
        if (getSourcesError) {
            setErrorMessage("Failed to load all Sources! Please try again");
        }
        if (createSourceError) {
            setErrorMessage(createSourceError.message);
        }
        if (!createSourceLoading || !loadingSources) {
            setInfoMessage('');
        }
    }, [createSourceError, getSourcesError]);

    useEffect(() => {
        if (createSourceData) {
            setInfoMessage("Source added successfully!");
            setErrorMessage('');
            handleClose();
        }
    }, [createSourceData]);

    useEffect(() => {
        if (createSourceLoading) {
            setInfoMessage("Creating Source...");
        }
    }, [createSourceLoading]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddSource();
        }
    };

    const handleAddSource = async () => {
        if (!newSourceName || !newSourceEmail) {
            setErrorMessage("Please fill in both Source Name and Source Email.");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(newSourceEmail)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        await createSource({ variables: { createSource: { name: newSourceName, email: newSourceEmail } } });
        refetchSources();
        setErrorMessage('');
    };

    return (
        <>
            {show &&
                <Modal title={"Add Source"} onClose={handleClose}>
                    <div className="space-y-2 mb-6 flex gap-2 flex-col">
                        <section>
                            <label htmlFor="sourceName" className="text-sm font-semibold mb-2">Source Name</label>
                            <input
                                id="sourceName"
                                value={newSourceName}
                                onChange={(e) => { setNewSourceName(e.target.value); setErrorMessage('') }}
                                placeholder=" Enter the name of the source"
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pn-blue focus:border-pn-blue block p-2.5 pac-target-input"
                                onKeyDown={handleKeyPress}
                            />
                        </section>
                        <section>
                            <label htmlFor="sourceEmail" className="text-sm font-semibold mb-2">Source Email</label>
                            <input
                                id="sourceEmail"
                                value={newSourceEmail}
                                onChange={(e) => { setNewSourceEmail(e.target.value); setErrorMessage('') }}
                                placeholder="Enter the email of the source"
                                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pn-blue focus:border-pn-blue block p-2.5 pac-target-input"
                                onKeyDown={handleKeyPress}
                            />
                        </section>
                        {infoMessage && (
                            <div className="mb-4 p-4 bg-gray-100 text-center text-bold text-gray-700 rounded-lg">
                                {infoMessage}
                            </div>
                        )}
                        {errorMessage && (
                            <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                                {errorMessage}
                            </div>
                        )}

                        <div className='flex gap-2'>
                            <button
                                onClick={handleClose}
                                className="py-2 text-pn-blue bg-white rounded-lg hover:bg-gray-300 mt-2 flex-1 border-[1px] hover:border-white border-pn-blue"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddSource}
                                className="py-2 bg-pn-blue text-white rounded-lg hover:bg-pn-dark-yellow mt-2 flex-1 disabled:bg-gray-300 "
                                disabled={createSourceLoading || !!errorMessage}
                            >
                                {createSourceLoading ? "Adding..." : "Add Source"}
                            </button>
                        </div>
                    </div>

                </Modal>
            }
        </>
    );
}
