import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { Property, PropertyFile } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { CloseButton } from "../../../components/form/close";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_DELETE_PROPERTY_VIDEO, MUTATION_UPDATE_PROPERTY_VIDEOS_ORDER } from "../../../graphql/mutations/property";
import { UploadService } from "../../../services/upload.service";
import { getPropertyVideoUrl } from "../../../utils/video.utils";

interface Props {
  property?: Property;
  refresh?: () => void;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

enum VideoStatus {
  Pending = 'pending',
  Uploading = 'uploading',
  Error = 'error',
  Completed = 'completed',
  Deleting = 'deleting',
}

interface NewVideo {
  status: VideoStatus,
  progress?: number,
  errorMessage?: string,
  file: File,
}

export function PropertyVideosModal({ property, refresh, show, onShowHide }: Props) {

  const [newVideos, setNewVideos] = useState<NewVideo[]>([]);
  const [currentVideos, setCurrentVideos] = useState<PropertyFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const [deletePropertyVideo, { loading: deleting, error: deleteError }] = useMutation(MUTATION_DELETE_PROPERTY_VIDEO, { errorPolicy: 'all' });
  const [updatePropertyVideosOrder, { loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_PROPERTY_VIDEOS_ORDER, { errorPolicy: 'all' });

  useEffect(() => {
    return () => {
      setNewVideos([]);
      setCurrentVideos([]);
    }
  }, []);

  useEffect(() => {
    if (property && property.videos) {
      setCurrentVideos(property.videos.slice().sort((a, b) => a.order - b.order));
    } else {
      setCurrentVideos([]);
    }
  }, [property]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newNewVideos = acceptedFiles.map(file => ({ status: VideoStatus.Pending, progress: 0, file }));
    setNewVideos([...newVideos, ...newNewVideos]);
  }, [newVideos]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': [],
      'video/mov': [],
      'video/avi': [],
      'video/flv': [],
      'video/wmv': [],
      'video/3gp': [],
      'video/mkv': [],
      'video/webm': [],
    },
    maxSize: 500 * 1024 * 1024,
    multiple: true,
    autoFocus: true,
  });

  function getRootClassname() {
    let className = "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100";
    if (isDragAccept) {
      className += " border-pn-dark-yellow";
    } else if (isDragReject) {
      className += " border-red-500";
    }
    return className;
  }

  function deleteNewVideo(index: number) {
    const videos = newVideos.filter((_, i) => i !== index);
    setNewVideos(videos);
  }

  function deleteCurrentVideo(index: number) {
    if (!property) {
      return;
    }
    const videoToDelete = currentVideos[index];
    deletePropertyVideo({ variables: { propertyId: property.id, videoId: videoToDelete.id } });
    const videos = currentVideos.filter((_, i) => i !== index);
    setCurrentVideos(videos);
  }

  function getActionElement() {
    return (
      <div className="flex content-end gap-2 gap">
        <FormButton title="Done" type="button" color="white" onClick={close} />
        <FormButton title={!uploading ? 'Upload' : 'Uploading...'} type="button" onClick={submit} disabled={!(newVideos.filter(i => i.status !== VideoStatus.Completed)).length || uploading} />
      </div>
    )
  }

  async function close() {
    await saveOrder();
    onShowHide && onShowHide(false);
    refresh && refresh();
    setNewVideos([]);
  }

  async function submit() {
    setErrorMessage(undefined);

    if (!property?.id) {
      setErrorMessage('No property selected');
      return;
    }

    setUploading(true);
    for (let newVideo of newVideos) {
      if (newVideo.status === VideoStatus.Completed) {
        continue;
      }

      setNewVideos(prevVideos => prevVideos.map(video => {
        if (video.file.name === newVideo.file.name) {
          return { ...video, status: VideoStatus.Uploading, progress: 0 };
        }
        return video;
      }));

      const formData = new FormData()
      formData.set('propertyId', property.id);
      formData.append('video', newVideo.file)
      try {
        await UploadService.client.post('/property/upload/videos', formData, {
          onUploadProgress: (progressEvent) => {
            setNewVideos(prevVideos => prevVideos.map(video => {
              if (video.file.name === newVideo.file.name) {
                const progress = Math.round((progressEvent.loaded / (progressEvent?.total || progressEvent.loaded)) * 100);
                return { ...video, progress };
              }
              return video;
            }));
          },
        });
        setNewVideos(prevVideos => prevVideos.map(video => {
          if (video.file.name === newVideo.file.name) {
            return { ...video, status: VideoStatus.Completed };
          }
          return video;
        }));
      } catch (err) {
        setNewVideos(prevVideos => prevVideos.map(video => {
          if (video === newVideo) {
            return { ...video, status: VideoStatus.Error, errorMessage: 'Failed to upload' };
          }
          return video;
        }));
      }
    }
    setUploading(false);
  }

  function getVideoClassname(status: VideoStatus) {
    let className = "border border-radius";
    if (status === VideoStatus.Error) {
      className += " border-red-500 border-4";
    }
    if (status === VideoStatus.Completed) {
      className += " border-green-500 border-4";
    }
    return className;
  }

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === index) return;

    const reorderedVideos = [...currentVideos];
    const [draggedVideo] = reorderedVideos.splice(draggedIndex, 1);
    reorderedVideos.splice(index, 0, draggedVideo);
    setDraggedIndex(index);
    setCurrentVideos(reorderedVideos);
  };

  const handleDrop = () => {
    setDraggedIndex(null); // Clear dragged state after drop
  };

  const saveOrder = async () => {
    const orderedVideos = currentVideos.map((video, index) => ({
      id: video.id,
      order: index + 1,
    }));

    try {
      await updatePropertyVideosOrder({
        variables: {
          propertyId: property?.id,
          videos: orderedVideos,
        },
      });
      onShowHide?.(false); // Close the modal after saving
    } catch (err) {
      console.error("Failed to save image order", err);
    }
  };

  return show ?
    <Modal title="Manage Property Videos" onClose={close} actionElement={getActionElement()}>
      <p className="text-sm text-gray-500">No changes are made until you click Upload</p>
      {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
      <div className="flex flex-col items-center justify-center w-full" onSubmit={submit}>
        <input type="hidden" name="propertyId" value={property?.id} />
        <label {...getRootProps()} htmlFor="dropzone-file" className={getRootClassname()} onClick={(e) => e.stopPropagation()}>
          <div className="flex flex-row items-center justify-center pt-2 pb-2 gap-lg">
            <svg className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <div className="flex flex-col items-center justify-center">
              <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
              <p className="text-xs text-gray-500">MP4, MOV, AVI, FLV, WMV, 3GP, MKV, WEBM (500MB)</p>
            </div>
          </div>
          <input {...getInputProps({
            onClick: (e) => e.stopPropagation(), // Prevent double triggering on input click
          })} id="dropzone-file" type="file" className="hidden" />
        </label>
        {property && currentVideos.length > 0 && (
          <>
            <h2 className="mt-4 mb-2 text-lg text-bold">Current Videos</h2>
            <div className="grid grid-cols-3 gap">
              {currentVideos.map((video, index) => (
                <div
                  key={index}
                  className="relative cursor-move"
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDrop={handleDrop}
                >
                  <CloseButton className="absolute -right-1 -top-1" onClick={() => deleteCurrentVideo(index)} />
                  <video
                    src={getPropertyVideoUrl(property.id, video)}
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noremoteplayback noplaybackrate"
                    className="border border-radius"
                  />
                </div>
              ))}
            </div>
          </>
        )}

        {newVideos.length > 0 && (
          <>
            <h2 className="mt-4 mb-2 text-lg text-bold">New Videos</h2>
            <div className="grid grid-cols-3 gap">
              {newVideos.map((newVideo, index) => (
                <div key={index} className="relative">
                  <CloseButton className="absolute bg-white -right-1 -top-1" onClick={() => deleteNewVideo(index)} />
                  <video
                    src={URL.createObjectURL(newVideo.file)}
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noremoteplayback noplaybackrate"
                    className="border border-radius"
                  />
                  {newVideo.errorMessage && <p className="mt-2 text-xs text-center text-red-500 text-bold">{newVideo.errorMessage}</p>}
                  {newVideo.status === VideoStatus.Uploading && (
                    <div className="absolute bottom-0 left-0 right-0 h-2 bg-gray-300 rounded-sm">
                      <div className="h-full bg-pn-dark-yellow" style={{ width: `${newVideo.progress}%` }} />
                      <p className="text-xs text-center text-bold text-pn-dark-yellow">{newVideo.progress !== 100 ? 'Uploading...' : 'Processing...'}</p>
                    </div>
                  )}
                  {newVideo.status === VideoStatus.Completed && (
                    <p className="mt-2 text-xs text-center text-green-700 text-bold">Uploaded</p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>

    : null

}