import { FormEvent, useState } from "react";
import { Modal } from "./modal";

interface ConfirmModalProps {
    title: string;
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
    errorMessage?: string;
    confirmationMessage?: string | null;
    warning?: boolean;
}

export function ConfirmModal({
    title,
    onConfirm,
    onCancel,
    isOpen,
    errorMessage,
    confirmationMessage,
    warning,
}: ConfirmModalProps) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleConfirm = async (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await onConfirm();  // Perform the confirmation action
            setIsSubmitting(false);
        } catch (error) {
            console.error(error);
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {isOpen && (
                <Modal
                    title={title}
                    onClose={onCancel}  // Close the modal if the user clicks outside or on the close button
                    onSubmit={handleConfirm}
                    errorMessage={errorMessage}
                    warning={warning}
                >
                    <div className="space-y-4">
                        {confirmationMessage ?
                            <p data-test="confirmation-message">{confirmationMessage}</p> :
                            <p data-test="confirmation-message">Are you sure you want to continue with this action?</p>
                        }

                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                className="btn py-[1rem] btn-secondary"
                                onClick={onCancel}  // Cancel the action
                                data-test="cancel-btn"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn py-[1.1rem] btn-primary"
                                disabled={isSubmitting}  // Disable the button while submitting
                                data-test="confirm-btn"
                            >
                                {isSubmitting ? "Processing..." : "Confirm"}
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
