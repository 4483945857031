import { gql } from "@apollo/client";

export const MUTATION_ADD_PROPERTY = gql`
  mutation addProperty(
    $title: String!
    $description: String!
    $excerpt: String!
    $address: String!
    $city: String!
    $lat: Float!
    $lng: Float!
    $bed: Int!
    $bath: Int!
    $sqFootage: Int!
    $agreementPrice: BigInt!
    $transactionFee: BigInt!
    $serviceCharges: BigInt!
    $fundingDeadlineDate: DateTime!
    $status: PropertyStatus!
    $type: String!
    $minimumInvestment: Int!
  ) {
    addProperty(
      data: {
        title: $title
        description: $description
        excerpt: $excerpt
        address: $address
        city: $city
        lat: $lat
        lng: $lng
        bed: $bed
        bath: $bath
        sqFootage: $sqFootage
        agreementPrice: $agreementPrice
        transactionFee: $transactionFee
        serviceCharges: $serviceCharges
        fundingDeadlineDate: $fundingDeadlineDate
        status: $status
        type: $type
        minimumInvestment: $minimumInvestment
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PROPERTY = gql`
  mutation updateProperty(
    $id: String!
    $title: String!
    $description: String!
    $excerpt: String!
    $address: String!
    $city: String!
    $lat: Float!
    $lng: Float!
    $bed: Int!
    $bath: Int!
    $sqFootage: Int!
    $agreementPrice: BigInt!
    $transactionFee: BigInt!
    $serviceCharges: BigInt!
    $fundingDeadlineDate: DateTime!
    $status: PropertyStatus!
    $type: String!
    $minimumInvestment: Int!
    $manualFundedDays: BigInt
    $manualFunded: BigInt
  ) {
    updateProperty(
      data: {
        id: $id
        title: $title
        description: $description
        excerpt: $excerpt
        address: $address
        city: $city
        lat: $lat
        lng: $lng
        bed: $bed
        bath: $bath
        sqFootage: $sqFootage
        agreementPrice: $agreementPrice
        transactionFee: $transactionFee
        serviceCharges: $serviceCharges
        fundingDeadlineDate: $fundingDeadlineDate
        status: $status
        type: $type
        minimumInvestment: $minimumInvestment
        manualFunded: $manualFunded
        manualFundedDays: $manualFundedDays
      }
    ) {
      id
    }
  }
`;

export const MUTATION_DELETE_PROPERTY_IMAGE = gql`
  mutation deletePropertyImage($propertyId: String!, $imageId: String!) {
    deletePropertyImage(data: { propertyId: $propertyId, imageId: $imageId })
  }
`;

export const MUTATION_DELETE_PROPERTY_DOCUMENT = gql`
  mutation deletePropertyDocument($propertyId: String!, $documentId: String!) {
    deletePropertyDocument(
      data: { propertyId: $propertyId, documentId: $documentId }
    )
  }
`;

export const MUTATION_DELETE_PROPERTY_VIDEO = gql`
  mutation deletePropertyVideo($propertyId: String!, $videoId: String!) {
    deletePropertyVideo(data: { propertyId: $propertyId, videoId: $videoId })
  }
`;

export const MUTATION_SOFT_DELETE_PROPERTY = gql`
  mutation SoftDeleteProperty($id: String!) {
    softDeleteProperty(id: $id) {
      id
      title
      deletedAt
    }
  }
`;

export const MUTATION_UPDATE_PROPERTY_DOCUMENTS_ORDER = gql`
  mutation updatePropertyDocumentsOrder(
    $propertyId: String!
    $documents: [DocumentOrder!]!
  ) {
    updatePropertyDocumentsOrder(
      data: { propertyId: $propertyId, documents: $documents }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PROPERTY_IMAGES_ORDER = gql`
  mutation updatePropertyImagesOrder(
    $propertyId: String!
    $images: [ImageOrder!]!
  ) {
    updatePropertyImagesOrder(
      data: { propertyId: $propertyId, images: $images }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PROPERTY_VIDEOS_ORDER = gql`
  mutation updatePropertyVideosOrder(
    $propertyId: String!
    $videos: [VideoOrder!]!
  ) {
    updatePropertyVideosOrder(
      data: { propertyId: $propertyId, videos: $videos }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PROPERTIES_ORDER = gql`
  mutation updatePropertiesOrder(
    $properties: [PropertiesOrderUpdateRequest!]!
  ) {
    updatePropertiesOrder(data: $properties) {
      id
    }
  }
`;
