import { useLazyQuery, useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Role, User } from "../../__generated__/graphql";
import { Form } from "../../components/form/form";
import { LoadingSpinner } from "../../components/loading/spinner";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Headline } from "../../components/text/headline";
import { QUERY_GET_ADMIN_BY_ID } from "../../graphql/queries/user";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { AdminFormMessages } from "./components/messages";
import { AdminFormActions } from "./sections/action-form";
import { AdminFormProfileSection } from "./sections/profile-form";
import { AdminFormStatusSection } from "./sections/status-form";
import { MUTATION_ADD_ADMIN, MUTATION_UPDATE_ADMIN } from "../../graphql/mutations/admin";
import { QUERY_ROLES } from "../../graphql/queries/role";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";

export function AdminPage() {
  const location = useLocation()
  const navigate = useNavigate();

  const [user, setUser] = useState<User>();

  const [getUser, { data, loading, error }] = useLazyQuery(QUERY_GET_ADMIN_BY_ID, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [getRoles, { data: rolesData, loading: rolesLoading, error: rolesError }] = useLazyQuery(QUERY_ROLES, { errorPolicy: 'all' })
  const [addAdmin, { data: submitData, loading: submitting, error: submitError }] = useMutation(MUTATION_ADD_ADMIN, { errorPolicy: 'all' })
  const [updateAdmin, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_ADMIN, { errorPolicy: 'all' })
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [added, setAdded] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [formError, setFormError] = useState<string>("");

  const [roles, setRoles] = useState<Role[]>([]);

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(rolesError);
  useAuthAndErrorHandling(submitError);
  useAuthAndErrorHandling(updateError);



  useEffect(() => {
    if (!AuthService.authenticate) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const _added = queryParams.get('added');
    const _updated = queryParams.get('updated');
    if (id) {
      getUser({ variables: { id } })
      setMode('edit');
    } else {
      setMode('add');
    }
    if (_added) {
      setAdded(true);
    }
    if (_updated) {
      setUpdated(true);
    }

    getRoles();

    // Navigate back to property list when Escape key pressed
    const handleKeyNav = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          navigate(AppRoute.PropertyList)
          break;
      }
    };

    window.addEventListener('keydown', handleKeyNav);

    return () => {
      window.removeEventListener('keydown', handleKeyNav);
    };
  }, []);

  useEffect(() => {
    if (rolesData && rolesData.roles) {
      setRoles(rolesData.roles);
    }
  }, [rolesData]);

  useEffect(() => {
    if (submitData && submitData.addAdmin && submitData.addAdmin.id) {
      navigate(`${AppRoute.AdminList}?id=${submitData.addAdmin.id}&added=1`);
    }
  }, [submitData]);

  useEffect(() => {
    if (updateData && updateData.updateAdmin && updateData.updateAdmin.id) {
      navigate(`${AppRoute.AdminList}?updated=1`);
    }
  }, [updateData]);

  useEffect(() => {
    if (data && data.userById) {
      setUser(data.userById);
    }
  }, [data]);

  function refresh() {
    if (!user) {
      return;
    }
    getUser({ variables: { id: user?.id } })
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    if (mode === 'edit') {
      if ((formData.get('password') as string).length > 0 && (formData.get('password') as string).length < 5) {
        setFormError("Password must be at least 5 characters long.")
        return;
      }
      updateAdmin({
        variables: {
          id: user?.id,
          firstName: formData.get('firstName') as string,
          lastName: formData.get('lastName') as string,
          email: formData.get('email') as string,
          password: formData.get('password') as string,
          phone: formData.get('phone') as string,
          status: formData.get('status') as string,
          roleId: formData.get('role') as string,
        }
      })
      return;
    } else {
      if ((formData.get('password') as string).length < 5) {
        setFormError("Password must be at least 5 characters long.")
        return;
      }
      addAdmin({
        variables: {
          firstName: formData.get('firstName') as string,
          lastName: formData.get('lastName') as string,
          email: formData.get('email') as string,
          password: formData.get('password') as string,
          phone: formData.get('phone') as string,
          status: formData.get('status') as string,
          roleId: formData.get('role') as string,
        }
      })
    }
  }

  return (
    <>
      <Sidebar />

      <section className='content w-full'>

        <Headline className="flex gap-lg">
          {mode === 'add' ? 'Add Admin' : 'Update Admin'}
          {loading && <LoadingSpinner size={32} />}
        </Headline>

        <div className="flex flex-col md:flex-row gap">

          <Form className="flex-3" onSubmit={submit} disabled={submitting || updating}>
            <>

              <AdminFormMessages submitting={submitting} updating={updating} added={added} updated={updated}
                updateError={updateError} submitError={submitError} />

              <AdminFormProfileSection user={user} roles={roles} mode={mode} />
              <AdminFormStatusSection user={user} />
              {formError && <div className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey">{formError}</div>}
              <AdminFormActions mode={mode} />

            </>
          </Form>

        </div>

      </section>

    </>
  )
}