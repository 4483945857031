import { Autocomplete, useJsApiLoader, Libraries } from "@react-google-maps/api";
import { HTMLProps, useEffect, useState, useMemo } from "react";
import { toHumanReadableCurrency } from "../../utils/string.utils";

interface Props extends HTMLProps<HTMLInputElement> {
  label: string;
  containerClassname?: string;
  isCurrency?: boolean;
  defaultCity?: string;
  defaultLat?: number;
  defaultLng?: number;
}
let addressAutocomplete: google.maps.places.Autocomplete;

export function FormAddress({ label, containerClassname, className, isCurrency, defaultCity, defaultLat, defaultLng, ...props }: Props) {
  const libraries: Libraries = useMemo(() => ['places', 'marker'], []);
  const loaderOptions = useMemo(() => ({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: libraries,
    language: 'en',
    region: 'US',
  }), [libraries]);

  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader(loaderOptions);

  const [value, setValue] = useState(props.defaultValue || '');
  const [address, setAddress] = useState(props.defaultValue || '');
  const [city, setCity] = useState(defaultCity || '');
  const [lat, setLat] = useState<number | undefined>(defaultLat);
  const [lng, setLng] = useState<number | undefined>(defaultLng);

  useEffect(() => {
    setValue(props.defaultValue || '');
    setAddress(props.defaultValue || '');
    setCity(defaultCity || '');
    setLat(defaultLat);
    setLng(defaultLng);
  }, [props.defaultValue]);

  useEffect(() => {
    setCity(defaultCity || '');
    setLat(defaultLat);
    setLng(defaultLng);
  }, [defaultCity, defaultLat, defaultLng]);

  function getInputClassname() {
    let className = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ';
    className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 ';
    return className;
  }

  function getId() {
    return props.id || label.toLowerCase().replace(' ', '_');
  }

  function onAutocompleteLoad(autocomplete: google.maps.places.Autocomplete) {
    addressAutocomplete = autocomplete;
  }

  function onPlaceChanged() {
    if (!addressAutocomplete) return;
    const place = addressAutocomplete.getPlace();

    if (place.geometry?.location) {
      const latValue = place.geometry.location.lat();
      const lngValue = place.geometry.location.lng();

      setLat(latValue);
      setLng(lngValue);

      console.log('lat:', latValue);
      console.log('lng:', lngValue);
    } else {
      console.warn('No geometry found for place:', place);
      setLat(undefined);
      setLng(undefined);
    }

    const formattedAddress = place.formatted_address || '';
    setAddress(formattedAddress);
    setValue(formattedAddress);

    const city = getCity(place);
    setCity(city);

    console.log('address:', formattedAddress);
    console.log('city:', city);
  }

  function getCity(place: google.maps.places.PlaceResult): string {
    const components = place.address_components;
    if (!components) return '';

    let cityComponent = components.find(c => c.types.includes("postal_town"))
      || components.find(c => c.types.includes("neighborhood"))
      || components.find(c => c.types.includes("locality"))
      || components.find(c => c.types.includes("administrative_area_level_2"))
      || components.find(c => c.types.includes("administrative_area_level_1"));

    return cityComponent ? cityComponent.long_name : '';
  }

  return (
    <div className={`flex flex-col mb-4 ${containerClassname}`}>
      <label className="text-sm font-semibold mb-2" htmlFor={getId()}>{label}</label>
      <div className="relative">
        <p className="absolute top-3 text-slate-500 right-5 text-sm">{isCurrency ? toHumanReadableCurrency(parseInt(value as string) * 100) : ''}</p>
        {isGoogleApiLoaded &&
          <Autocomplete
            options={{
              componentRestrictions: { country: 'uk' },
            }}
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}>
            <input
              className={getInputClassname()}
              id={getId()}
              value={value}
              {...props}
              onChange={(e) => {
                setValue(e.currentTarget.value);
                setLat(undefined); // Clear latitude
                setLng(undefined); // Clear longitude
              }}
            />
          </Autocomplete>
        }
        <input type="hidden" name="lat" id="lat" value={lat?.toString() || ''} />
        <input type="hidden" name="lng" id="lng" value={lng?.toString() || ''} />
        <input type="hidden" name="address" id="address" value={address} />
        <input type="hidden" name="city" id="city" value={city} />
      </div>
    </div>
  );
}

