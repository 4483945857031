import { useLazyQuery, useMutation } from '@apollo/client';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import PlusCircleIcon from '@heroicons/react/24/outline/PlusCircleIcon';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleAction, RoleModule, User, UserStatus } from '../../__generated__/graphql';
import { FormButton } from '../../components/form/button';
import { LoadingPlaceholder } from '../../components/loading/placeholder';
import { Sidebar } from '../../components/sidebar/sidebar';
import { Tab } from '../../components/tab/tab';
import { MUTATION_DELETE_ADMIN, MUTATION_UPDATE_ADMIN } from '../../graphql/mutations/admin';
import { QUERY_GET_PAGINATED_USER_LIST } from '../../graphql/queries/user';
import { PaginatedData } from '../../interfaces/pagination.interface';
import { AppRoute } from '../../routes';
import { AuthService } from '../../services/auth.service';
import { useAuthAndErrorHandling } from '../../utils/invalid-token.util';
import { PaginationBar } from '../../components/pagination/pagination.component';

export function AdminListPage() {

  const tabs = useRef([
    {
      name: 'Active',
      action: RoleAction.ListActive,
    },
    {
      name: 'Blocked',
      action: RoleAction.ListBlocked,
    },
  ]);

  const navigate = useNavigate();

  const [getUsers, { loading, error, data }] = useLazyQuery<{ users: PaginatedData<User> }>(QUERY_GET_PAGINATED_USER_LIST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [updateAdmin, { loading: updateLoading, error: updateError, data: updateData }] = useMutation(MUTATION_UPDATE_ADMIN, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [deleteAdmin, { loading: deleteLoading, error: deleteError, data: deleteData }] = useMutation(MUTATION_DELETE_ADMIN, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [selectedTabItem, setSelectedTabItem] = useState('Active');
  const [selectedRow, setSelectedRow] = useState<number>();

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(updateError);
  useAuthAndErrorHandling(deleteError);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }


  }, []);

  // Navigate table using up, down and enter
  useEffect(() => {
    const handleKeyNav = (event: KeyboardEvent) => {
      if (!data) {
        return;
      }

      switch (event.key) {
        case 'Enter':
          if (selectedRow !== undefined && selectedRow >= 0 && selectedRow < data.users.items.length) {
            selectAdmin(data.users.items[selectedRow].id);
          }
          break;
        case 'ArrowUp':
          if (selectedRow !== undefined && selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
          } else {
            setSelectedRow(data.users.items.length - 1);
          }
          break;
        case 'ArrowDown':
          if (selectedRow !== undefined && selectedRow < data.users.items.length - 1) {
            setSelectedRow(selectedRow + 1);
          } else {
            setSelectedRow(0);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyNav);

    return () => {
      window.removeEventListener('keydown', handleKeyNav);
    };
  }, [data, selectedRow, selectAdmin]);

  useEffect(() => {
    if (updateData) {
      getUsers({ variables: { take: 10, skip: 0, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
    }
  }, [updateData])

  useEffect(() => {
    if (deleteData) {
      getUsers({ variables: { take: 10, skip: 0, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
    }
  }, [deleteData]);

  useEffect(() => {
    getUsers({ variables: { take: 10, skip: 0, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
  }, [selectedTabItem]);

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getTabList() {
    return tabs.current
      .filter(tab => AuthService.hasRole(RoleModule.Admin, tab.action))
      .map(tab => tab.name);
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case 'Active':
        return [
          { field: 'user_type', operator: '=', value: 'admin' },
          { field: 'status', operator: '=', value: 'active' },
        ]
      case 'Blocked':
        return [
          { field: 'user_type', operator: '=', value: 'admin' },
          { field: 'status', operator: '=', value: 'blocked' },
        ]
      default:
        return {};
    }
  }

  function blockOrUnblock(user: User) {
    if (user.status === UserStatus.Unverified) {
      return;
    }
    if (user.status === UserStatus.Blocked) {
      updateAdmin({ variables: { id: user.id, status: UserStatus.Active } });
    } else {
      updateAdmin({ variables: { id: user.id, status: UserStatus.Blocked } });
    }
  }

  function selectAdmin(id: string) {
    if (!AuthService.hasRole(RoleModule.Admin, RoleAction.Update)) {
      return;
    }
    navigate(`${AppRoute.Admin}?id=${id}`);
  }

  function getFullName(user: User) {
    return [user.firstName, user.lastName].join(' ');
  }

  const refresh = () => {
    getUsers({
      variables: { take: 10, skip: 0, where: { filters: getFiltersForTabItem(selectedTabItem) } }
    });
  }

  const handleDateChange = (take: number, skip: number, startDate: string, endDate: string) => {
    getUsers({
      variables: {
        take,
        skip,
        startDate,
        endDate,
        where: {
          filters: [
            { field: 'user_type', operator: '=', value: 'admin' },
            { field: 'status', operator: '=', value: selectedTabItem.toLowerCase() },
          ]
        },
      }
    });
  }

  return (
    <>
      <Sidebar />

      <section className='content full properties'>
        <h1 className='mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>Admins</h1>

        <div className="flex flex-col-reverse md:flex-row md:justify-between">
          <Tab items={getTabList()}
            selectedTabItem={selectedTabItem}
            loading={loading}
            handleTabItemClick={handleTabItemClick}
          />
          {AuthService.hasRole(RoleModule.Admin, RoleAction.Add) && <div className='flex gap-2 mb-2 md:mb-0'>
            <a href="#" className='flex flex-wrap content-center justify-center w-full h-12 gap-2 px-4 py-2 transition-all duration-300 rounded-lg mb:w-auto bg-pn-blue hover:bg-pn-dark-yellow' onClick={() => navigate(AppRoute.Admin)}>
              <PlusCircleIcon width={24} className='text-white' />
              <span className='self-center text-sm font-semibold text-white'>Add Admin</span>
            </a>
          </div>}
        </div>

        <PaginationBar
          take={10}
          total={data?.users?.total || 0}
          currentTotal={data?.users?.items.length || 0}
          onDateChange={handleDateChange}
          defaultRangeType='All'
        />

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className='px-4 py-3 border-b'>Name</th>
              <th className='px-4 py-3 border-b'>Email</th>
              {/* <th className='hidden px-4 py-3 border-b md:table-cell'>Role</th> */}
              <th className='hidden px-4 py-3 border-b md:table-cell'>Status</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && data && data.users && data.users.items && data.users.items.map((user: User, index: number) => (
              <tr key={index}>
                <td className='px-4 py-3 text-sm text-nowrap'>{getFullName(user)}</td>
                <td className='px-4 py-3 text-sm'>{user.email}</td>
                {/* <td className='hidden px-4 py-3 text-sm text-right md:table-cell'></td> */}
                <td className='hidden px-4 py-3 text-sm text-center md:table-cell'>{user.status}</td>
                <td className='hidden float-right px-4 py-3 text-sm md:flex md:gap-2'>
                  {user.status === UserStatus.Active && AuthService.hasRole(RoleModule.Admin, RoleAction.Block) &&
                    <FormButton title='Block' size='xs' onClick={() => blockOrUnblock(user)} disabled={updateLoading} loading={updateLoading} />
                  }
                  {user.status === UserStatus.Blocked && AuthService.hasRole(RoleModule.Admin, RoleAction.Unblock) &&
                    <FormButton title='Unblock' size='xs' onClick={() => blockOrUnblock(user)} disabled={updateLoading} loading={updateLoading} />
                  }
                  {AuthService.hasRole(RoleModule.Admin, RoleAction.Update) &&
                    <FormButton title="Update" size='xs' onClick={() => selectAdmin(user.id)} />
                  }
                  {AuthService.hasRole(RoleModule.Property, RoleAction.Delete) &&
                    <FormButton title="Delete" size='xs' onClick={() => deleteAdmin({ variables: { id: user.id } })} />
                  }
                </td>
              </tr>
            ))}
            {loading && <tr><td colSpan={5} className='text-center'><LoadingPlaceholder /></td></tr>}
            {!loading && (!data || !data.users || data.users.items.length === 0) && <tr><td colSpan={5} className='text-sm text-center'>
              <div className='flex flex-col items-center gap-2 p-5'>
                <InformationCircleIcon width={60} />
                <p className='font-bold'>No {selectedTabItem} Admins</p>
              </div>
            </td></tr>}
          </tbody>
        </table>
      </section>

    </>
  )
}
